import { defineStore, acceptHMRUpdate } from 'pinia'

export const useCartStore = defineStore('Cart', {
    state: () => ({
        // Cart items
        items: [],

        // Processed cart used for order details page
        finalItems: [],

        // Cart drawer state
        drawer: false,

        // Customer
        customer: {
            id: null,
            email: null,
            firstName: null,
            lastName: null,
        },

        // Order
        order: {
            customer: null,
            status: 'api/statuses/1',
            total: null,
            confNumber: null,
            cancelReason: null,
            orderItems: null,
            pporder: null,
            note: null,
        },

        // error while processing order
        error: null,

        // Paypal order response
        ppResponse: null,

        // Paypal order status
        ppStatus: null,
    }),
    getters: {
        // Returns cart total amount
        totalAmount: (state) => {
            let currentPrice = 0
            const totalAmount = state.items.reduce((total, product) => {
                if (product.productPrices.totalCount > 0) {
                    currentPrice = Number(product.productPrices.edges[0].node.price)
                } else {
                    currentPrice = Number(product.price)
                }
                return total + currentPrice
            }, 0)
            return totalAmount
        },

        // Returns total number of items in cart
        totalItems: (state) => {
            return state.items.length
        },

        // Returns order items
        getOrderItems() {
            const orderItems = []
            this.items.forEach((element) => {
                const tempItem = {}
                tempItem.product = element.id
                if (element.productPrices.totalCount > 0) {
                    tempItem.price = element.productPrices.edges[0].node.price
                } else {
                    tempItem.price = element.price
                }
                // tempItem.price = element.productPrices.edges[0].node.price
                tempItem.quantity = 1
                orderItems.push(tempItem)
            })
            return orderItems
        },
    },
    actions: {
        // Adds a product to cart
        addProduct(product) {
            const exist = this.items.find((item) => item.id === product.id)
            if (!exist) {
                this.items.push(product)
            }
        },

        // Removes a product from cart
        removeProduct(product) {
            const index = this.items.findIndex((item) => item.id === product.id)
            if (index > -1) {
                this.items.splice(index, 1)
            }
        },

        // Update customer
        async updateCustomer(data) {
            this.customer.id = data.customers.edges[0].node.id
            this.customer.firstName = data.customers.edges[0].node.firstName
            this.customer.lastName = data.customers.edges[0].node.lastName
        },

        // Create a PayPal order
        async createPaypalOrder() {
            console.log('Creating PayPal order...')

            const ppOrder = { description: 'Your order details from satyatunes.com', amount: {}, items: [] }

            const amount = {
                value: this.totalAmount,
                breakdown: {
                    item_total: {
                        currency_code: 'USD',
                        value: this.totalAmount,
                    },
                },
            }

            ppOrder.amount = amount

            // prepare items
            const crtItems = this.items
            const ppItems = []
            for (let i = 0; i < crtItems.length; i++) {
                const tempItem = {}
                tempItem.name = crtItems[i].name
                tempItem.description = crtItems[i].tagline
                tempItem.unit_amount = { currency_code: 'USD', value: 0 }
                if (crtItems[i].productPrices.totalCount > 0) {
                    tempItem.unit_amount.value = crtItems[i].productPrices.edges[0].node.price
                } else {
                    tempItem.unit_amount.value = crtItems[i].price
                }

                tempItem.quantity = 1
                ppItems.push(tempItem)
            }

            ppOrder.items = ppItems

            return ppOrder
        },

        // Process order
        async processOrder() {
            // const GqlInstance = useGql()

            if (this.ppStatus !== 'CANCELLED' && this.customer.email) {
                try {
                    const data = await $fetch(`/api/customer/${this.customer.email}`)
                    if (data && data.customers.totalCount > 0) {
                        console.log('processOrder: customer exists, updating data...')
                        await this.updateCustomer(data)
                    } else {
                        console.log('processOrder: customer does not exist, creating...')
                        try {
                            const data = await $fetch('/api/customer/create', {
                                method: 'POST',
                                body: {
                                    status: '/api/statuses/1',
                                    firstName: this.customer.firstName,
                                    lastName: this.customer.lastName,
                                    email: this.customer.email,
                                    subscribed: 1,
                                    note: 'created via Order',
                                    clientMutationId: 'created-customer-successfully',
                                },
                            })
                            if (data) {
                                console.log('processOrder: created customer successfully')
                                // Need to call API so that we can retrieve the customer ID for order payload
                                const resp = await $fetch(`/api/customer/${this.customer.email}`)
                                if (resp && resp.customers.totalCount > 0) {
                                    console.log('processOrder: retrieved customer after creating, updating data...')
                                    await this.updateCustomer(resp)
                                }
                            }
                        } catch (e) {
                            this.error = e.message
                            console.log('processOrder: error creating customer = ', e)
                        }
                    }
                } catch (e) {
                    this.error = e.message
                    console.log('processOrder: error fetching customer = ', e)
                }
            } else {
                // Use internal customer id for cancelled order
                this.customer.id = '/api/customers/3'
            }

            // Step 3 - Customer exists or created already at this point so create and save order in db
            if (this.customer.id && !this.error) {
                this.order.customer = this.customer.id
                this.order.total = this.totalAmount.toString()
                this.order.confNumber = Math.random().toString(36).slice(3)
                this.order.orderItems = this.getOrderItems

                // Check order status
                switch (this.ppStatus) {
                    case 'COMPLETED':
                        this.order.status = '/api/statuses/9'
                        this.order.cancelReason = null
                        this.order.pporder = this.ppResponse.id
                        break
                    case 'CANCELLED':
                        this.order.pporder = null
                        this.order.status = '/api/statuses/5'
                        break
                    default:
                        break
                }

                if (this.order.orderItems?.length > 0) {
                    await this.saveOrder()
                } else {
                    this.error = 'No order items to process...'
                }
            }
        },

        // Save order in database
        async saveOrder() {
            const cart = useCartStore()
            try {
                await $fetch('/api/order/save', {
                    method: 'POST',
                    body: this.order,
                    onResponse({ response }) {
                        if (response.status === 200) {
                            console.log('saveOrder>onResponse: saved order in database')
                            // cart.isProcessing = false
                            cart.finalItems = cart.items.slice()
                            // cart.reset()
                        }
                    },
                    onResponseError({ response }) {
                        this.error = response.status
                        console.log('saveOrder>onResponseError: error saving order in database')
                    },
                })
            } catch (e) {
                this.error = e.message
                console.log('saveOrder: error calling order api = ', e)
            }
        },

        // Reset state
        async reset() {
            // Clear cart if no error
            if (!this.error) {
                this.items = [] //.splice(0, this.items.length)
            }
        },

        // Full reset
        async fullReset() {
            this.finalItems = []
            this.order.customer = null
            this.order.total = null
            this.order.confNumber = null
            this.order.cancelReason = null
            this.order.orderItems = null
            this.order.pporder = null
            this.order.status = '/api/statuses/1'
            this.order.note = null
            this.ppResponse = null
            this.ppStatus = null
            this.error = null
        },

        // Update Paypal order capture response
        updatePPResponse(response) {
            this.ppResponse = response
            // Update customer data
            this.customer.email = this.ppResponse.payer.email_address
            this.customer.firstName = this.ppResponse.payer.name.given_name
            this.customer.lastName = this.ppResponse.payer.name.surname
        },

        // Update Paypal order capture status
        async updatePPStatus(status) {
            this.ppStatus = status
        },
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}
